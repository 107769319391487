import React, { useContext, Fragment } from 'react';
import Layout from '@components/layout';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import SEO from '@components/seo';
import Video from '@components/video';
import { PrismicImage, PrismicVideo } from 'src/models/Prismic.types';
import EdmusContext from '@components/application.provider';
import useLabels from '@hooks/useLabels';

export const query = graphql`
  {
    prismic {
      allEdmusClubs {
        edges {
          node {
            content
            _meta {
              lang
            }
            imageHeader
            introduction
            name
            title
            video
            videoImage
            images {
              image
              text
            }
          }
        }
      }
    }
  }
`;

const WineAngels = (props: any) => {
  const { labels } = useLabels();
  const { language } = useContext(EdmusContext);
  const doc = props.data.prismic.allEdmusClubs.edges.find(
    (doc: any) => doc.node._meta.lang.slice(0, 2) === language
  );

  const addTimecode = (video: any) => {
    try {
      const src = video.html.match(/src\s*=\s*\\*"(.+?)\\*"\s*/)[1];
      video.html = video.html.replace(src, `${src}&start=126`);
      return video;
    } catch (error) {
      console.log(error);
      return video;
    }
  };

  if (!doc) return null;

  return (
    <Layout>
      <div className='wine-angels'>
        <img
          className='img-bar'
          src={doc.node.imageHeader.url}
          alt={doc.node.imageHeader.alt}
        />
        <SEO title={doc.node.title} />
        <div className='red-title space-top-20'>{doc.node.title}</div>
        <div className='container sub-container space-top-20 text-center'>
          <div className='columns space-bottom-30'>
            <div className='col'>{RichText.render(doc.node.introduction)}</div>
          </div>
        </div>
        <div className='p-relative'>
          <Video
            video={addTimecode(doc.node.video)}
            image={doc.node.videoImage}
          />
        </div>
        <div className='container sub-container space-top-40'>
          <div className='columns'>
            <div className='col'>{RichText.render(doc.node.content)}</div>
          </div>
        </div>

        <div className='container sub-container space-top-40 text-center'>
          <h4 className='full-width text-blue text-bold'>
            {labels.WINE_ANGELS_BECOMING}
          </h4>
          <div className='full-width'>
            {labels.WINE_ANGELS_BECOMING_PROCESS}
          </div>
          <Fragment>
            <div className='columns space-top-20 hide-sm'>
              <div className='col-2 hide-md'></div>
              {doc.node.images &&
                doc.node.images.map(
                  (i: { image: PrismicImage; text: string }, key: number) => (
                    <div
                      className={
                        key === 1 ? 'col-2  col-md-4' : 'col-3  col-md-4'
                      }
                      key={key}
                    >
                      <img
                        className='img-triple'
                        src={i.image.url}
                        alt={i.image.alt}
                      />
                    </div>
                  )
                )}
              <div className='col-2 hide-md'></div>
            </div>
            <div className='columns  space-bottom-20 hide-sm'>
              <div className='col-2 hide-md'></div>
              {doc.node.images &&
                doc.node.images.map(
                  (i: { image: PrismicImage; text: string }, key: number) => (
                    <div
                      className={
                        key === 1 ? 'col-2   col-md-4' : 'col-3   col-md-4'
                      }
                      key={key}
                    >
                      <div className='full-width text-center uppercase font-fjalla font-07 inner-space-20'>
                        {i.text}
                      </div>
                    </div>
                  )
                )}
              <div className='col-2 hide-md'></div>
            </div>
          </Fragment>

          <Fragment>
            <div className='columns space-top-20 show-sm'>
              {doc.node.images &&
                doc.node.images.map(
                  (i: { image: PrismicImage; text: string }, key: number) => (
                    <div className={'col-sm-12 space-bottom-20'} key={key}>
                      <img
                        className='img-triple '
                        src={i.image.url}
                        alt={i.image.alt}
                      />
                      <div className='full-width text-center uppercase font-fjalla font-07 inner-space-10'>
                        {i.text}
                      </div>
                    </div>
                  )
                )}
            </div>
          </Fragment>

          <div className='full-width font-fjalla text-blue text-center font-07'>
            {labels.WINE_ANGELS_BECOMING}
          </div>
          <div className='full-width mt-1 space-bottom-40 wine-angels-link'>
            <a href='https://www.wineangels.com/' target='_blank'>
              {labels.WINE_ANGELS_POSTULATE}
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default WineAngels;
